import React from "react";
import './doctors.css';
import DoctorsCarousel from "./Carousel/DoctorsCarousel";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Doc from "../../Store/Doctors";

const Doctors = observer(() => {

    const setData = (img, name, surname, patronimic, specialization, docs, education, qualification, about) => {
        Doc.setData(img, name, surname, patronimic, specialization, docs, education, qualification, about);
    }

    const doctors = [
        {
            id: 1,
            img: "../../IMG/DSC02747-2.jpg",
            surname: "Таценко",
            name: "Елена",
            patronimic: "Генадьевна",
            specialization: [`Врач общей практики `, <br />, `Кандидат медицинских наук`, <br />, `Терапевт, ортопед, ортодонт`],
            link: "Tacenko",
            docs: [],
            education: [`- Выпускница Кубанского государственного медицинского университета 2007`, <br />,
                `Специальность: «Стоматология»`, <br />,
                `- Проходила интернатуру в Кубанском государственном медицинском университете (КГМУ).
            Специальность: «Стоматология ортопедическая» `, <br />,
                `- 2007 Получила сертификат «Кубанского
            государственного медицинского университета» по специальности «Стоматология общей
            практики»`, <br />,
                `- 2007 Диплом о профессиональной переподготовке «Кубанский государственный
            медицинский университет». Специальность: «Ортодонтия» `, <br />,
                `- Получила сертификат «Кубанского
            государственного медицинского университета» по специальности «Ортодонтия»`, <br />,
                `- 2008 Диплом о
            профессиональной переподготовке «Кубанский государственный медицинский университет».
            Специальность: «Организация здравоохранения и общественного здоровья» `, <br />,
                `- Получила сертификат
            «Кубанского государственного медицинского университета» по специальности «Организация
            здравоохранения и общественного здоровья»`],
            qualification: [`Постоянно повышает свой профессионализм, посещая в г. Москве и г. Санкт-Петербурге ведущие
            зарубежные (W.Proffit (США), V.Cachifesta (Италия)), и российские (А. Тугарин, С. Попов,
            А. Тихонов) конференции и семинары.`],
            about: [`- Кандидат медицинских наук.`, <br />, `- Врач высшей категории 2022
            Член Орто-Клуба г. Краснодар и г. Ростова-на-Дону.`, <br />,
                `- Член профессионального общества
            Ортодонтов России.`],

        },
        {
            id: 2,
            img: "../../IMG/DSC02599-2.jpg",
            name: "Екатерина",
            surname: "Еремеева",
            patronimic: "Романовна",
            specialization: [`Врач общей практики `, <br />, `Ординатор, ортодонт`],
            link: "Eremeeva",
            docs: [],
            education: `- Образование по специальности «Стоматология 31.05.03» в КубГМУ в 2022 году с отличием.`,
            qualification: [`- Обучение в Пикассо по цефалометрическому анализу, работа с КЛКТ-снимками, 2022.`, <br />, `
            - Прошла онлайн-конгресс «Путь начинающего стоматолога», 2023.`, <br />, `
            - Онлайн-курс «Ортодонтическая подготовка перед протезированием», 2023.`],
            about: [`- Врач ординатор-ортодонт. `, <br />, `- Владение современными протоколами диагностики.`],
        },
        {
            id: 3,
            img: "../../IMG/IMG_20230323_214441.jpg",
            name: "Александр",
            surname: "Смык",
            patronimic: "Александрович",
            link: "Smyk",
            specialization: "Хирург-имплантолог",
            docs: [],
            education: [`- Диплом об окончании медицинской академии 2015 г.`, <br />, `
            - Сертификат об окончании интернатура - стоматолог общей практики 2016 г.`, <br />, `
            - Сертификат по хирургической стоматологии - 2017 г.`, <br />, `
            - 2010-2015 г Днепропетровская государственная медицинская академия`],
            qualification: [`- Консультатирование, составление комплексных планов лечения.`, <br />, `
            - Удаление зубов.
            - Лечение воспалительных заболеваний полости рта.`, <br />, `
            - Владение современными техниками в области
            дентальной имплантации (одномоментной и отсроченной-
            коррекции объёма тканей полости рта (костные и мягкотканные пластики) - открытый/закрытый
            синус лифтинг, вертикальная/горизонтальная аугментация костного гребня, пластика десны).`, <br />, `
            - Протезирования на дентальных имплантатах`],
            about: ``,
        },
        {
            id: 4,
            img: "../../IMG/DSC02705-2.jpg",
            name: "Фатима",
            surname: "Куашева",
            patronimic: "Магометовна",
            specialization: [`Врач общей практики `, <br />, `Терапевт, детский стоматолог`],
            link: "Kuasheva",
            docs: [],
            education: [`- 2015 г. Кубанский государсвенный медицинский университет, Краснодар, стоматологический факультет, Стоматолог.`, <br />, `
            - 2016 г. Центральный институт повышения квалификации и профессиональной переподготовки, Стоматология ортопедическая, Врач стоматолог ортопед.`, <br />, `
            - 2016 г. Кубанский государственный медицинский университет, Краснодар, Интернатура, Стоматология общей практики.`, <br />, `
            - 2020 г. Автономной некоммерческой организации допоолниктельного профессионального образования "Центральный многопрофильный институт", Повышение квалификации, Стоматология общей практики.`],
            qualification: [`- 2022 г. 3й конгресс прямой реставрации Wowresto, Dentist education.`, <br />, `
            - 2022 г. Реставрация Pro, Diamonds Dental Clinic.`, <br />, `
            - 2020 г. Эндодонтия повышенной сложности, Dental course.`, <br />, `
            - 2020 г. Адгезивные керамические реставрации в практике
            - врача-стоматолога, Dental art.`, <br />, `
            - 2019 г. Прямые реставрации фронтальной группы зубов Direct Anterior
            Denting.education.`, <br />, `
            - 2018 г. The bond- Алгоритмы адгезионных процессов, Hexagon international LTD.`, <br />, `
            - 2016 г. Клиника боли лица и челюстей, Mis course.`, <br />, `
            - 2014 г. Современные вопросы применения 3D-технологий в
            современной стоматологической практике, Пикассо.`],
            about: [`- Умение работать с оптикой (бинокуляры, микроскоп).`, <br />, `- Применение в практике новейших технологий.`],
        }
    ]

    return (
        <div className="backgr">
            <div className="doctors">

                <div className="titleDoc">
                    <h1>Наша команда</h1>
                </div>
                <div>
                    <ul className="breadcrumb">
                        <li><a href="/">Главная</a></li>
                        <li>Команда</li>
                    </ul>
                </div>
                <div className="textDoc">
                    <p>
                        Наши сотрудники - это команда высококвалифицированных стоматологов, которые обладают обширным опытом и знаниями в области стоматологии. Мы гордимся нашей командой и уверены в их способности предоставить нашим пациентам качественную стоматологическую помощь.
                    </p>
                </div>
                <div className="docBtn">
                    <a href="/portfolio"><button>Смотреть портфолио</button></a>
                    <a href="/vacancy"><button>Наши вакансии</button></a>
                </div>
                {window.innerWidth < 900 ?
                    <DoctorsCarousel />
                    :
                    <div className="photoDoc">
                        <div className="noPhoto">
                            <div className="stick">
                                <h1>
                                    Команда
                                </h1>
                            </div>
                        </div>
                        {doctors?.map(doc => (
                            <Link key={doc.id} to={`/doctors/${doc.link}`} onClick={() => { setData(doc.img, doc.name, doc.surname, doc.patronimic, doc.specialization, doc.docs, doc.education, doc.qualification, doc.about) }}>
                                <div className="ph">
                                    <img src={doc.img} alt={doc.surname} />
                                    <h3>
                                        {doc.surname} {doc.name} {doc.patronimic}
                                    </h3>
                                    <p>
                                        {doc.specialization}
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </div>}
            </div>
        </div>
    )
})

export default Doctors;